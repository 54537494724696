.modalProducts{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #131010bd;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.close {
  background-color: transparent;
  outline: none;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 11px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border: 2px solid #C5C5C5;
  border-radius: 50%;
}

button.close:after,
button.close:before{
  content:' ';
  position: absolute;
  width: 2px;
  height: 12px;
  background-color: #c5c5c5;
}
button.close img {
  position: absolute;
  z-index: 99999999;
  left: -35px;
  top: -9px;
  width: 50px;
  opacity: 1;
}
button.close:before{
  transform: rotate(45deg);
}
button.close:after{
  transform: rotate(-45deg);
}

.modalProduts .product {
  display: flex;
  text-align: center;
  /* align-items: center; */
  max-width: 660px;
  margin: 35px auto;
  flex-direction: row;
  gap: 35px;
}
.infosProductContainer{
  display: flex;
  flex-direction: column;
}

img.img-fluid.imgProductModal {
  margin-top: 30px;
  width: 100%;
  padding: 0px 20px;
  /* width: 170px; */
  height: 170px;
  margin-bottom: -40px;
  /* border-radius: 170px; */
}
.inforProduct,
.inforProductDescription p{
  margin-top: 25px;
  text-align: left;

}
.inforProduct h5{
  font-size: 24px;
}
.infoCalendar{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  color: #b9b9b9;
}
.infoCalendar p{
  color: #b9b9b9 !important;
}
.infoCalendar svg{
  margin-right: 10px;
}
.inforProduct p {
  letter-spacing: 0px;
  color: #B2B2B2;
  opacity: 1;
  margin-bottom: 0;
}

.d-flex.justify-product-center.border {
  width: 450px;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  .containerProducts{
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .d-flex.justify-product-center.border {
    width: 150px;
  }
  .containerProduts{
    width: 100%;
    height: 70%;
    margin: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .modalProducts .product {
    margin: 35px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 35px;
  }
  .infosProductContainer{
    align-items: initial;
  }
}
