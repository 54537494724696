.tabs__buttons {
  display: flex;
  /* flex-wrap: wrap; */
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);

  border-radius: 10px 10px 0 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: both mandatory;
}
.tabs__buttons__theater {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  width: 1072px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.tab-button {
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
  padding: 15px 30px;
  text-transform: initial;
  color: #fff;
  fill: #fff;
  /* color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54); */
  cursor: pointer;
  transition: all 0.3s;
  border: 0;
  background: transparent;
  outline: 0;
  scroll-snap-align: start;
}

.tab-button:nth-last-child(2){
  scroll-snap-align: end;
}

.tab-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.tab-icon-svg svg{
  width:18px;
  height:18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-icon-svg path {
  fill: inherit;
}
.tab-button:hover {
  color: #000;
  fill: #000;
  /* background: #eeeeee; */
}

.tab-button--active {
  color: #fff;
  fill: #fff;
}

.tab-button--active:hover {
  color: #fff;
  fill: #fff;
}

.tab-indicator {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s;
  background: #fff;
}

.tabs__panels {
  /* overflow: hidden; */
  width: 100%;
  /* height: 500px; */
}

.tabs__panels__theater {
  /* overflow: hidden; */
  width: 1072px;
  /* height: 500px; */
}

.tabs__tracker {
  display: flex;
  transition: transform 0.3s;
  width: 100% !important;
  transform: translateX(0) !important;
}

.tab-panel.tab-panel {
  width: 100%;
  padding: 25px 0;
  transition: opacity 0.3s;
  display: none;
}

.tab-panel.tab-panel--active {
  display: block;
}

@media(max-width:768px){
  .tabs__buttons {
    padding: 0 4rem 0 2rem;
    box-sizing: content-box;
  }
}
.tabs {
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.tabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
