h1, h2, h3, h4, h5 {
  color: #000;
}
.tab-pane.fade.show.active {
  color: #000;
}
.tab-title {
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  padding: 15px 30px 15px 30px;
  min-width: 150px;
  margin: 0 2px;
  border: none;
  border-radius: 6px;
  background: #fff;
}

.headerTop2 {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 0rem 2rem;
}

.time {
  font: normal normal 600 22px/29px Proxima Nova;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: medium;
  margin-left: 10px;
}


.hora{
width: 122px;
height: 164px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px -13px 24px #0000001C;
border-radius: 10px;
opacity: 1;
margin-left: -35px;
}

.intervalo{
  width: 118px;
  height: 20px;
  font: normal normal normal 16px/27px Proxima Nova;
  letter-spacing: 0px;
  color: #C1C1C1;
  opacity: 1;
  margin-left: 365px;
  font-size: small;
}

.intervalo_2{
width: 540px;
height: 22px;
display: flex;
justify-content: center;
font: normal normal 600 22px/29px Proxima Nova;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-left: 150px;
margin-top: 50px;
}


.doc {
  height: 18px;
  margin-top: 10px;
  font: normal normal 600 18px/29px Proxima Nova;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-left: 30px;
  display: flex;
  align-items: flex-end;
  font-size: small;
  font-weight: bolder;
  margin: 5px;
  display: flex;
  align-items: 10px;
  justify-content: unset;
  margin-left: 30px;
  margin-top: 10px;
}

.decritption{
width: 118px;
height: 20px;
font: normal normal normal 16px/27px Proxima Nova;
letter-spacing: 0px;
color: #C1C1C1;
opacity: 1;
margin-left: 30px;
display: flex;
align-items: flex-end;
font-size: small;
}

.text{
    width: 640px;
    height: 22px;
    font: normal normal 600 22px/29px Proxima Nova;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 30px;
}

li {
  list-style-type: none;
}


.imagenDoc{
  margin: 20px; 
  width: 100px !important;
  height:100px !important;
 
}

.container{
  display: flex;
  align-items: row;
  padding: 5px !important;
}

.subText{
width: 580px;
height: 43px;
text-align: left;
font: normal normal normal 14px/18px Proxima Nova;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-left: 30px;
}

.de_tab.tab_style_4 .de_nav {
  text-align: left;
  width: 100%;
  background: none;
  display: inline-block;
  border-radius: 5px;
  padding: 0;
}
.tab-title--active, .tab-title:hover {
  border-radius: 6px;
  border: none;
  background-color: #02C90F;
}
.de_tab.tab_style_4 .de_nav li span, .tab_style_4 h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.tab_style_4 h4 {
  margin: 0;
  font-size: 16px;
}
.tab-title h3, h4{
  color: #000;
}
.tab-title--active h3,
.tab-title--active h4 {
  color: #fff;
}

.img-2{
  border-color: #B9B9B9; 
  border-radius: 80px; 
  border-style: solid;
  border-width: thick;
  margin-left: 50px;
  width: 100px;
  height: 100px;
  margin-top: 40px;
}

.de_tab .de_tab_content {
  padding: 0;
}

/*Agenda Infor*/
.schedule-infor{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 5px 0;
  border-radius: 10px;
  background: #fff;
}
.schedule-infor .horas {
  /* max-width: 116px;
  width: 100%; */
  text-align: center;
  margin: 0 1rem;
}
.horas > svg {
  color: #b9b9b9;
  font-size: 22px;
}
.horas > p{
  font-size: 19px;
  font-weight: 700;
}
.schedule-infor .imgSpeaker {
  /* max-width: 110px;
  width: 100%; */
  text-align: center;
  margin: 0 1rem;
}
.imgSpeaker > img {
  width: 80px;
}
.sc-name {
  /* max-width: 150px; */
  width: 100%;
}
.sc-name span {
  text-align: center;
  letter-spacing: 0px;
  color: #C1C1C1;
  opacity: 1;
}
.schedule-infor .content {
  /* width: 100%;*/
  flex: 1;
  margin: 0 1rem;
}


@media only screen and (max-width: 1200px){

   .container {
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    
  }

  ul {
    /* width: 100%; */
    flex: 1 1;
    margin: 0 -2rem;
}


.hora {
  border-radius: 10px;
  opacity: 1;
  margin-left: -35px;
  width: 10px;
  height: 10px;
}


.intervalo_2{

  margin-left: -120px;
  margin-top: 0px;

}

.intervalo{
  margin-left: 90px;
  margin-top: 20px;
}

.img-2{
  margin-left: 300px;
}

.doc{
  margin-top: 20px;
  margin-left: 285px;
}

.decritption{
  margin-left: 285px;
}


}


@media only screen and (max-width: 992px){
  .horas p{
    margin-bottom: 1rem;
    text-align: center!important;
  }
  .imgSpeaker > img {
    margin-bottom: 0.5rem;
    margin-bottom: 1rem;
  }
  .sc-name {
    margin-left: 0;
    margin-bottom: 1rem;
  }
  .content h3, p {
    text-align: left;
  }
}

@media only screen and (max-width: 640px){
 
  .img-2{
    border-color: #B9B9B9; 
    border-radius: 80px; 
    border-style: solid;
    border-width: thick;
    width: 80px;
    height: 80px;
    margin-top: 0px;
    margin-left: 99px;
  }

  .hora {
    border-radius: 10px;
    opacity: 1;
    margin-left: -35px;
    width: 10px;
    height: 10px;
}

.doc{
  margin-left: 70px;
}

.decritption{
  margin-left: 80px;
}

.subText{
    width: 300px;
    height: 43px;
    margin-left: -30px;
    font-size: smaller;
    margin-bottom: 40px;
}

.text {
  font-size: small;
  margin-left: -25px;
}

}


@media only screen and (max-width: 360px){

.subText {
    width: 270px;
    height: 43px;
    margin-left: -30px;
    font-size: smaller;
    margin-bottom: 40px;
}

}

@media only screen and (max-width: 320px){

  .text{
    font-size: 10px;
  }

}