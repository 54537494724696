.modalSpeakers{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #131010bd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerSpeakers{
  /* background-color: #fff;
  color: #000;
  width: 70%;
  height: auto;
  margin: 0 50px;
  overflow: overlay;
  border-radius: 10px;
  max-height: 600px; */
}

button.close {
  background-color: transparent;
  outline: none;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 11px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border: 2px solid #C5C5C5;
  border-radius: 50%;
}

button.close:after,
button.close:before{
  content:' ';
  position: absolute;
  width: 2px;
  height: 12px;
  background-color: #c5c5c5;
}
button.close img {
  position: absolute;
  z-index: 99999999;
  left: -35px;
  top: -9px;
  width: 50px;
  opacity: 1;
}
button.close:before{
  transform: rotate(45deg);
}
button.close:after{
  transform: rotate(-45deg);
}

.modalSpeakers .content {
  display: flex;
  text-align: center;
  /* align-items: center; */
  max-width: 660px;
  margin: 35px auto;
  flex-direction: row;
  gap: 35px;
}
.infosSpeakerContainer{
  display: flex;
  flex-direction: column;
}

img.img-fluid.imgSpeakerModal {
  width: 170px;
  height: 170px;
  border-radius: 170px;
}
.inforSpeaker,
.inforSpeakerDescription p{
  margin-top: 39px;
  text-align: left;
}
.inforSpeaker h2{
  margin-bottom: 0;
}

.inforSpeaker p {
  letter-spacing: 0px;
  color: #B2B2B2;
  opacity: 1;
  margin-bottom: 0;
}

.d-flex.justify-content-center.border {
  width: 450px;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  .containerSpeakers{
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .d-flex.justify-content-center.border {
    width: 150px;
  }
  .containerSpeakers{
    width: 100%;
    height: 70%;
    margin: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .modalSpeakers .content {
    margin: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .infosSpeakerContainer{
    align-items: center;
  }
}
