@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/ProximaNova-Light.otf);
    font-style: normal;
    font-weight: 300;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/ProximaNova-LightItalic.otf);
    font-style: italic;
    font-weight: 300;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/proximanova-regular-webfont.ttf);
    font-style: normal;
    font-weight: 400;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/ProximaNova-Semibold.otf);
    font-style: normal;
    font-weight: 500;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/ProximaNova-Bold.otf);
    font-style: normal;
    font-weight: 700;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/ProximaNova-Bold.otf);
    font-style: normal;
    font-weight: 800;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url(../assets/fonts/ProximaNova/ProximaNova-Black.otf);
    font-style: normal;
    font-weight: 900;
}