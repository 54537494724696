.modalFooter{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #131010bd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerFooter{
  color: #000;
  width: 50%;
  margin: 0 50px;
  border-radius: 10px;
  max-height: 600px;
}

button.close {
  background-color: transparent;
  outline: none;
  width: 32px;
  height: 32px;
  border: none;
  cursor: pointer;
  top: 16px;
  right: 11px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border: 2px solid #C5C5C5;
  border-radius: 50%;
}

button.close:after,
button.close:before{
  content:' ';
  position: absolute;
  width: 2px;
  height: 12px;
  background-color: #c5c5c5;
}
button.close img {
  position: absolute;
  z-index: 99999999;
  left: -35px;
  top: -9px;
  width: 50px;
  opacity: 1;
}
button.close:before{
  transform: rotate(45deg);
}
button.close:after{
  transform: rotate(-45deg);
}

.modalFooter .content {
  text-align: center;
  max-width: 660px;
  margin: 35px auto;
}

img.img-fluid.imgFooterModal {
  width: 130px;
}
.inforFooter,
.inforFooterDescription p{
  margin-top: 39px;
  text-align: left;
}
.inforFooter h2{
  margin-bottom: 0;
}

.inforFooter p {
  letter-spacing: 0px;
  color: #B2B2B2;
  opacity: 1;
  margin-bottom: 0;
}

.d-flex.justify-content-center.border {
  width: 450px;
  margin: 0 auto;
}

@media screen and (min-width: 1600px) {
  .containerFooter{
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .d-flex.justify-content-center.border {
    width: 150px;
  }
  .containerFooter{
    width: 100%;
    height: 70%;
    margin: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .modalFooter .content {
    margin: 35px;
  }
}
#contact{
  /* background-image: url(../images/bg_contact.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-forms{
  /* background-color: #fff; */
  border: 2px solid #979C96;
  border-radius: 11px;
  padding: 50px;
}

.contact-logo{
  width: 100%;
  max-width: 220px;
  margin-bottom: 25px;
}

.tel-number{
  font-size: 20px;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 0;
}

.contact-us{
  color:#fff;
  margin-top: 20px;
  width: 86%;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 0px;
}

#principal {
  position: relative;
  overflow: hidden;
}

.main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  padding: 100px;
  background-color: #000;
}

.container{
  width: 100%;
}

.warning{
  margin-top: 50px;
}

.radio-container{
  padding: 0 120px;
}

.container-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 5%;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #393939;
  border-radius: 50%;
  border: 1px solid #666360;
}

/* On mouse-over, add a grey background color */
.container-radio .checkmark:hover  {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #ff1341;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.grid-layout{
  display: grid;
  /* width: 80%; */
  grid-gap: 40px;
  /* padding: 0 120px; */
  grid-template-areas:
  "nome sobrenome"
  "email cpf"
  ;
  margin-bottom: 50px;
}

input{
  background: #393939;
  border-radius: 2px;
  border: 2px solid #666360;
  width: 100%;
  padding: 16px;
  display :flex;
  align-items: center;
  color: #fff;
  font-family: var(--fontFamily);
}


.input1{
  grid-area: nome;
}

.input2{
  grid-area: sobrenome;
}

.input3{
  grid-area: email;
}

.input4{
  grid-area: cpf;
}

.description{
  /* display: block; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  height: 30vh;
  margin-top: 50px;
  background: #393939;
  border-radius: 2px;
  border: 2px solid #666360;
  width: 65%;
  padding: 16px;
  display :flex;
  align-items: center;
  color: #fff;
  margin-left: 120px;
}

.institutional-pages div .divisor{
  margin-left: 12px;
  margin-right: 12px;
}

.agreement-text{
  color: #000;
  font-size: 16px;
  width: 100%;
  margin-top: 10px;
}

.title-contact {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #000;
}

.btn-dark{
  width: 100%;
  max-width: 220px;
  background-color: #000;
  color: #f0f0f0;
  font-size: 16px;
  padding: 10px 10px;
  margin-top: 35px;
  font-family: var(--fontFamily);
}

.btn-dark:hover{
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
}

.contact-services{
  font-size: 16px;
  text-transform: uppercase;
  color: #FF1341;
  margin-bottom: 0px;
}

#contact .socials-contact a, link{
  color: #FF1341;
}

#contact i{
  margin-left: 5px;
  margin-right: 5px;
  font-size: 26px;
}

#contact-principal .form-control{
  background-color: #ffffff;
  border: 1px solid#000;
  border-radius: 4px;
  font-size: 13px;
  color: #000;
  padding: 20px 15px;
}


@media screen and (max-width: 600px) {
  .contact-forms{
    padding: 10px;
    text-align: center;
  }
}
.row{
  justify-content: center;
}
