.notFound-container{
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
}

.notFound-animation{
  width: 100%;
  height: 100%;
  text-align: center;
}
.notFound-animation-lostInSpace{
  object-fit: contain;
  max-width: 180px;
}
.notFound-animation-number404{
  max-width: 180px;
  margin-top: -44px;
}

.notFound-content{
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.notFound-container nav{
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 60px;
  background: linear-gradient(90deg, #000, rgba(0, 0, 0, 0.6));
  padding: 1rem;
}
.notFound-container nav img{
  display: block;
  object-fit: contain;
}

.notFound-content h2{
  color:#111;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 0;
}
.notFound-content h3{
  color:#FF1341;
  font-size: 1rem;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 4px;
  height: initial;
  font-weight: 700;
  line-height: 1.4;
}
.notFound-content p{
  color:#111;
  font-size: .77rem;
  line-height: 1.4;
  text-align: center;
  max-width: 700px;
}

.notFound-container footer{
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  gap:1rem;
}
.notFound-container footer > div{
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap:2rem
}
.notFound-container footer > a{
  border:1px solid #ccc;
  color:#ccc;
  border-radius: 3px;
  padding: 4px 1rem;
  font-size: .77rem;
}
.notFound-container footer > a:hover{
  color: #9e9e9e;
  border-color: #9e9e9e;
}

@media(min-width:1920px){
  .notFound-animation-lostInSpace{
    object-fit: contain;
    max-width: 350px;
    transform: 175px;
  }
  .notFound-animation-number404{
    max-width: 350px;
    margin-top: -64px;
  }
}
