
.headernavbarsss {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headerUtilnavss {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headernavbarsssend {
  display: flex;
  flex-direction: row;
  /* padding: 20px 0; */
  align-items: center;
  justify-content: center;
}

.headerUtilnavssend {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.bosyvreibroder {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.bosyvreibroderpai {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-height: auto !important;
  background-size: 100% 100%!important;
  background-repeat: no-repeat;
  padding-top: 11px;
}
.bosyvreibroderpai2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-height: auto !important;
  background-size: 100% 100%!important;
  background-repeat: no-repeat;
}
.event-background-image{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100% !important;
}
.centerrere {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  border: 0px;
  font-size: 20px;
}
.centerrere:hover {
  font-size: 21px;
}
.infotabna {
  display: flex;
  height: 100%;
  padding: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.infotabna span b p {
  margin: 0;
}
img#log-img {
  max-width: 120px;
  width: auto;
  max-height: 20px;
}
@media only screen and (min-width: 320px) and (max-width:767px) {
  .infotabna span b p {
    max-width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .headerUtilnavssend {
    /* padding-top: 80px ; */
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width:767px) and (orientation: landscape) {
  .infotabna span b p {
    max-width: 100%!important;
  }
}

/* Moto G4/5 e Galaxy S5 */
@media only screen and (max-device-width: 360px) and (-webkit-device-pixel-ratio: 3) {
  .infotabna span b p {
    max-width: 6ch;
  }
}

/* Pixel 2/2 XL */
/* @media only screen and (min-width: 411px) and (max-width: 767px) {
  .infotabna span b p {
    max-width: 12ch;
    background: green;
  }
} */

/* iphone 5/SE */
@media screen and (max-device-width : 320px) and (-webkit-device-pixel-ratio: 2){
  .infotabna span b p {
    max-width: 4ch;
  }
}

/* iphone 6/7/8 e X*/
@media
only screen and (min-device-width : 375px) and (max-device-width : 736px) and (-webkit-device-pixel-ratio: 2), /* iphone 6/7/8 */
only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio: 3) {/* iphone X */
  .infotabna span b p {
    max-width: 8ch;
  }
}

/* iphone 6/7/8 Plus*/
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (-webkit-device-pixel-ratio: 3) {
  .infotabna span b p {
    max-width: 12ch;
  }
}

.modalViewPage{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color:rgba(0,0,0,0.6);
  top: 0px;
  z-index: 99999999999;
  position: fixed !important;
}

.modalViewPageContent{
  display: flex;
  align-items: center !important;
  flex-direction: column;
  justify-content: center;
  align-content: center !important;
  width: 90vw;
  max-height: 80vh;
}

.rowviewcloseddiv{
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;

  align-items: center;
  width: 100%;
  height: 30px;
}

.rowviewclosedIframe{
  display: flex;
  align-items: center !important;
  text-align: center !important;
  align-content: center !important;
  flex-direction: column;
  justify-content: center;
  outline: 0px !important;
  border: solid;
  border-width: 0px;

  max-width: 100%;
  max-height: 80vh;
  object-fit: cover;
}

.rowviewclosedDv{
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
  text-align: center !important;
  justify-content: center !important;
  min-width: 90%;
  height: 100%;
}

.xclose{
  display: flex;
  width: 100%;
  align-items: flex-start;
  text-align: left;
  font-size: 28px;
  cursor: pointer;
  color:white
}
