.itemtabbarvideo{
  display: flex;
  font-size: 1rem;
  position: relative;
  padding: 15px 30px;
  text-transform: initial;
  /* color: rgba(0, 0, 0, 0.54); */
  cursor: pointer;
  transition: all 0.3s;
  border: 0;
  background: transparent;
  outline: 0;
  color: #fff;
}
/* .divtabsvideo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
} */

.itemactivetabvideoview{
  color: #fff;
  border-bottom: 4px solid #fff;
}
.divtabsvideo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}


.videoview{
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;

}
.pl-8{
padding-left: 8px;
}

@media only screen and (max-width: 480px){
.buttons button {
  font-size: 8.6px!important;
}
}
@media only screen and (max-width: 768px) {
.buttons button {
  width: auto;
}
}

.container {
padding:0;
}

.buttons {
margin-bottom: 2rem;
}
.buttons button {
border-radius: 2px;
background-color: rgb(229, 229, 229);
color: rgb(25, 25, 25);
border-color: #000;
font-size: 12px;
border-radius: 10px;
}

.buttons button:active,
.buttons button:focus,
.buttons button:hover {
background-color: rgb(229, 229, 229);
color: rgb(25, 25, 25);
border-color: #000;
box-shadow: none;
}

.cemporcento{
  display: flex;
  width: 100%;
  height: 100%;
}
/* .maxdimexion{
  max-height:  675px;
  max-width: 1200px
} */

iframe img {
  height: 100% !important;
  width: 100% !important;
}


.heihViewLang {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px !important;
  height: 25px !important;
  font-size: 14px;
  background-color: #e5e5e5 !important;
  color: #000;
  outline: 0px;
  cursor: pointer;
  border: solid;
  border-width: 0px;
}
.heihViewLang:hover{
  background-color: #e5e5e5 !important;
}


.heihViewLangC,a {
  text-decoration: none !important;
  outline: 0px;
  cursor: pointer;
  border: solid;
  border-width: 0px;

}
.column{
  display: flex;
  flex-direction: column;
}
.modaLang{
  display: flex;
  position: absolute;
  top:0px;
  left: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99999999;
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(0,0,0,0.3);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 99999;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.flex{
    display: block;
}


.imgggggg{
display: inline;
/* max-width: 100%; */

object-position: center;
object-fit: unset;
margin: auto;
}

.link {
  background: none;
  border: none;
  text-decoration: none;
  color: #777;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
}

.dropdown.active > .link,
.link:hover {
  color: black;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  top: calc(100% + .25rem);
  background-color: white;
  padding: .75rem;
  border-radius: .25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
  /* opacity: 0; */
  /* pointer-events: none; */
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}

.dropdown.active > .link + .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.information-grid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 2rem;
}

.dropdown-links {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.login-form > input {
  margin-bottom: .5rem;
}
